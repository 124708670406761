<!--
 * @Author: Kiana
 * @Description: Person List
 * @params
 * list -> 列表基础配置
 * pid  -> textExpand组件绑定ID，如果页面大量使用textExpand组件绑定ID，需要绑定ID
 * mode -> 模式，根据不同模式，展示不同的样式功能等 ->params:talent:人才大厅|bid:投标管理
-->
<template>
  <div class="person-item" :style="{padding: mode=='bid'?'20px 0px':'20px 40px'}">
    <div class="person-item-left">
      <div class="person-head-icon" @click="jumpToPersonalHomepage(list.id)">
        <img :src="list.avatar" alt="head"/>
      </div>
      <div class="person-intro" :class="{ 'flex-1': mode === 'talent' }">
        <div class="person-intro-base">
          <div class="person-intro-base-left">
            <div class="base-name" @click="jumpToPersonalHomepage(list.id)">{{ list.name }}</div>
            <div class="base-com">
              <IconButton v-if="false" :icon="iconButtonConfig.icon" :style="iconButtonConfig.styleObj" uid="A">
                {{ iconButtonConfig.text }}
              </IconButton>
              <!--              <Isvg icon-class="platform-certification" />-->
              <Isvg v-if="list.certifications.email==9" icon-class="email-certification"/>
              <Isvg v-if="list.certifications.mobile==9" icon-class="mobile-certification"/>
              <Isvg v-if="list.certifications.real_name==9" icon-class="realname-certification"/>
              <Isvg v-if="list.certifications.company==9" icon-class="enterprise-certification"/>
            </div>
            <div class="base-icons">
              <PersonIcon v-for="(item, index) in list.auth_info_array" :key="index" :name="item"/>
            </div>
          </div>
          <div class="person-intro-base-right">
            <div class="base-favorite" v-show="list.id!=currentUser.id" @click="changeDoCollect(list)">
              <Isvg :icon-class="`${ isCollected ? 'collect-heart-full' : 'collect-heart' }`"
                    class-name="collect-icon"/>
            </div>
            <IconButton
                v-if="mode === 'talent'"
                :icon="'logo-main'"
                :style="iconButtonStyleObjB"
                uid="B"
                @click.native="jumpToPersonalHomepage(list.id)"
            >雇佣TA
            </IconButton>
          </div>
        </div>
        <div class="person-intro-self">
          <section>{{ list.slogan }}</section>
        </div>
        <div class="person-intro-info">
          <div class="info-status">
            <div class="info-status-item">
              <PersonLR :config="item" v-for="(item, index) in list.LR" :key="index"/>
            </div>
            <div class="info-status-grade">
              <div class="grade-star">
                <StarList :total="list.review_score"/>
              </div>
              <div class="grade-com">（{{ list.review_count }}个评论）</div>
            </div>
          </div>
          <div class="info-skills" v-if="list.skills.length > 0">
            <div class="skills-list">
              <span v-for="(item, index) in list.skills" :key="index">{{ item.name }}</span>
            </div>
          </div>
        </div>
        <div class="person-intro-write" v-if="mode === 'bid'">
          <div class="write-time">
            <Isvg icon-class="write-hirer" class-name="icon-pencil"/>
            <span>写给雇主</span>
            <span>{{ bidData.apply_time }}</span>
          </div>
          <div class="write-content">
            <TextExpand :pid="pid">{{ bidData.apply_memo }}</TextExpand>
          </div>
        </div>
        <div class="person-intro-detail" v-if="mode === 'talent' && list.intro !== ''">
          <Isvg icon-class="individual-resume" class-name="font-16"/>
          <span>个人简介</span>
          <section>{{ list.intro }}</section>
        </div>
      </div>
    </div>
    <div class="person-item-right" v-if="mode === 'bid'">
      <div class="right-text">竞标价</div>
      <div class="right-money">
        <span>{{ bidData.bid_amount }}</span>
        <span v-if="bidData.pay_way==1">元</span>
        <span v-else>元/小时</span>
      </div>
      <div class="right-button" v-if="!isPublic">
        <div v-show="bidData.status == 1">
          <p><i class="iconfont icon-no-information"></i>拒绝邀标</p>
        </div>
        <div v-show="bidData.status == 2">
          <p>已弃标</p>
        </div>
        <div v-show="bidData.status == 9">
          <p><i class="iconfont icon-selected"></i>已中标</p>
        </div>
        <div v-show="bidData.status == 3 || bidData.status == 4">
          <el-popconfirm title="您确定选中此用户吗？" @confirm="toSelectBid(bidData.id)">
            <button class="selectBtn" slot="reference">
              <Isvg icon-class="logo-white"/>
              选标
            </button>
          </el-popconfirm>
        </div>
        <div v-show="bidData.status == 9">
          <el-popconfirm title="该用户已经中标,您确定要取消他的中标资格？" @confirm="toCancelSelectBid(bidData.id)">
            <button class="selectBtn cancel" slot="reference">取消资格</button>
          </el-popconfirm>
        </div>
      </div>
      <div class="right-button" v-if="isPublic">
        <p class="openStatus" v-if="canTodoOpenLevel">
          <span>状态:</span>
          <el-select v-model="bidData.is_open_level" size="mini" placeholder="公开等级" style="width: 140px;" @change="changeDoOpenLevel" >
            <el-option v-for="(item,index) in openLeveloptions" :key="index" :value="item.value" :label="item.label" />
          </el-select>
        </p>
      </div>
      <div class="right-icon">
        <i class="icon-flag"></i>
        <Isvg v-if="!isPublic&&bidData.status == 9" icon-class="bidding" class-name="bidding"/>
        <Isvg v-if="isPublic&&bidData.status == 9" icon-class="bidding" class-name="bidding"/>
        <Isvg v-if="bidData.status == 2" icon-class="unbidding" class-name="bidding"/>
      </div>
    </div>
  </div>
</template>

<script>
import {IconButton} from '@/_components/IconButton';
import {TextExpand} from '@/_components/TextExpand';
import {StarList} from '@/_components/StarList';
import {setMoneyComma} from '@/utils/transformData';
import PersonIcon from './PersonIcon';
import PersonLR from './PersonLR';
import get from 'lodash-es';
import {postCollect, postUnCollect} from '@/_api/freelancer';
import {changeOpenLevel} from "@/api/task";

export default {
  name: '',
  data() {
    return {
      get,
      setMoneyComma,
      isCollected: this.list.is_collect,
      currentUser: {},
      openLeveloptions: [{
        value: 1,
        label: '公开'
      }, {
        value: 2,
        label: '私密'
      }, {
        value: 3,
        label: '有偿查看'
      }]
    };
  },
  props: {
    list: {
      type: Object,
      default: () => {
      },
      require: true
    },
    bidData: {
      type: Object,
      default: () => {
      }
    },
    pid: {
      default: 0
    },
    mode: {
      type: String,
      default: 'talent'
    },
    isPublic: {
      type: Boolean,
      default: false
    },
    canTodoOpenLevel: {
      type: Boolean,
      default: false
    }
  },
  components: {IconButton, PersonLR, TextExpand, PersonIcon, StarList},
  watch: {
    list(val) {
      this.isCollected = val.is_collect
    }
  },
  computed: {
    iconButtonConfig() {
      if (this.list.online) {
        return {
          icon: 'dialog',
          text: '在线沟通',
          styleObj: {
            background:
                'linear-gradient(270deg, #01C8D4 0%, #01C8D4 0%, #03D978 100%, #03D978 100%)',
            'box-shadow': '0px 2px 2px 0px rgba(0, 180, 255, 0.3)',
            color: '#fff'
          }
        };
      } else {
        return {
          icon: 'dialog',
          text: '留言给TA',
          styleObj: {
            background:
                'linear-gradient(0deg, #B5B5B5 0%, #B5B5B5 0%, #DCDDDD 100%, #DCDDDD 100%)',
            color: '#fff'
          }
        };
      }
    },
    iconButtonConfigCheck() {
      return {
        icon: 'logo-white',
        text: '点击查看',
        styleObj: {
          background: '#00B4FF',
          color: '#fff'
        }
      };
    },
    iconButtonStyleObjB() {
      return {
        boxShadow: '0px 1px 2px 1px rgba(220,221,221,0.5)',
        borderRadius: '10px',
        background: '#fff',
        height: '20px'
      };
    }
  },
  filters: {},
  created() {
  },
  mounted() {
    this.currentUser = this.$store.getters["useUserStore/getUserInfo"]
    // console.log(this.list);
  },
  beforeDestroy() {
  },
  methods: {
    jumpToPersonalHomepage(id) {
      //跳转到个人主页
      this.$router.push('/freelancer/info/' + id)
    },
    changeDoCollect(freelancer) {
      this.collectBtnCanToDo = false// 关闭防止连续点击
      let that = this
      setTimeout(function () {
        that.collectBtnCanToDo = true
      }, 2000)
      if (this.isCollected) {
        this.unCollect({freelancer_id: freelancer.id}, freelancer)
      } else {
        this.collect({freelancer_id: freelancer.id}, freelancer)
      }
    },
    toSelectBid(id) {
      this.$emit('selectBid', id)
    },
    toCancelSelectBid(id){
      this.$emit('cancelSelectBid', id)
    },
    async collect(data) {
      //收藏
      const res = await postCollect(data);
      if (!res) return
      this.isCollected = 1;
    },
    async unCollect(data) {
      //取消收藏
      const res = await postUnCollect(data);
      if (!res) return
      this.isCollected = 0;
    },
    async changeDoOpenLevel(is_open_level) {// 切换投标资料的开放状态

      const res = await changeOpenLevel({id:this.bidData.id, is_open_level:is_open_level})
      if(res.statusCode==200){
        this.$message.success( '操作成功' )
        this.list.is_open_level = is_open_level
      }else{
        this.$message.error(res.message)
      }
    }
  }
};
</script>

<style lang="less" scoped>
.person-item {
  display: flex;
  justify-content: space-between;
  padding: 20px 40px;
  position: relative;

  &-left {
    width: 100%;
    position: relative;
    .flex;

    .person-head-icon {
      cursor: pointer;
      flex-shrink: 0;
      width: 40px;
      height: 40px;

      img {
        width: 100%;
        border-radius: 50%;
      }
    }

    .person-intro {
      margin-left: 10px;
      .font-12;

      &-base {
        .flex;
        justify-content: space-between;
        align-items: center;

        &-left {
          .flex;

          .base-name {
            cursor: pointer;
            .font-16;
          }

          .base-com {
            margin-left: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            svg {
              margin-left: 10px;
            }
          }

          .base-icons {
            margin-left: 20px;
            .flex;

            ::v-deep .person-icon:not(:first-of-type) {
              margin-left: 10px;
            }
          }
        }

        &-right {
          .flex;
          align-items: center;

          .base-favorite {
            margin: 0 15px;
            font-size: 16px;

            .svg-icon {
              transition: all 0.3s ease;
              .pointer;
              color: #dcdddd;

              &:hover {
                color: #ff008e;
              }
            }
          }
        }
      }

      &-self {
        margin-top: 4px;

        & > section {
          word-break: break-all;
          line-height: 16px;
        }
      }

      &-info {
        margin-top: 20px;

        .info-status {
          .flex;

          &-item {
            .flex;
          }

          &-grade {
            .flex;
            align-items: center;
          }
        }

        .info-skills {
          margin-top: 20px;
          color: #575757;

          .skills-list {
            color: #000000;

            span {
              .font-12;
              padding: 5px 8px;
              color: #575757;
              background-color: #ededed;
              border-radius: 10px;
              margin-right: 10px;
              margin-bottom: 10px;
              .pointer;
              //已废弃，将于下个版本删除
              //&:not(:last-of-type)::after {
              //  content: '/';
              //  position: absolute;
              //  right: -10px;
              //}
            }
          }
        }
      }

      &-write {
        margin-top: 20px;

        .write-time {
          .icon-pencil {
            width: 20px;
            height: 13px;
          }

          & > span {
            margin-left: 10px;

            &:first-of-type {
              margin-left: 5px;
            }
          }
        }

        .write-content {
          margin-top: 10px;

          & > p {
            padding-right: 10px;
            .one-text-ellipsis;
          }

          // span {
          //   position: absolute;
          //   display: inline-block;
          // }
        }

        // .write-content-expand {
        // }
      }

      &-detail {
        margin-top: 20px;

        & > i {
          width: 15.34px;
          height: 13px;
          vertical-align: middle;
        }

        & > span {
          margin-left: 4px;
          color: #575757;
          vertical-align: middle;
        }

        & > section {
          line-height: 20px;
          display: inline;
          margin-left: 16px;
          vertical-align: middle;
          word-break: break-all;
        }
      }
    }
  }

  &-right {
    padding-right: 50px;
    min-width: 160px;
    position: relative;

    .right-text {
      .font-16;
      color: #575757;
    }

    .right-money {
      .font-16;
      margin-top: 8px;

      & > span {
        &:first-of-type {
          .font-24;
          font-weight: bold;
        }

        &:last-of-type {
          .font-12;
        }
      }
    }

    .right-icon {
      position: absolute;
      top: -20px;
      right: 0;

      .bidding {
        width: 24px;
        height: 48px;
      }
    }

    .right-button {

      .openStatus {
        display: flex;
        justify-content: left;
        align-items: center;
        margin-top: 20px;

        span {
          font-size: 14px;
          width: 50px;
        }
      }

      ::v-deep .icon-button {
        width: 100%;
        height: 20px;
        line-height: 20px;
        border-radius: unset;
        box-shadow: 0px 1px 2px 1px rgba(220, 221, 221, 0.5);
        margin-top: 20px;

        .icon-button-icon {
          width: 12px;
          height: 12px;
        }
      }
      div {
        p {
          margin-top: 5px;
          font-size: 12px;
          i {
            font-size: 12px;
          }
        }
      }
      .selectBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 120px;
        height: 25px;
        margin-top: 15px;
        background-color: #00a2e6;

        svg {
          margin-right: 10px;
        }
      }
      .selectBtn.cancel {
        background-color: #d4237a;
      }
    }
  }
}
</style>
