import { $Http } from '@/utils/http/axios';

const Api = {
  getPersonRecommendList: '/api/recommend/freelancer',
  getFreelancerList: '/api/freelancer/list',
  postCollect: '/api/freelancer/collect',
  postUnCollect: '/api/freelancer/collect/cancel'
};

export function getPersonLists(params) {
  return $Http.get({ url: Api.getPersonRecommendList, params });
}

export function getFreelancerList(params) {
  return $Http.get({ url: Api.getFreelancerList, params });
}

export function postCollect(params) {
  return $Http.post({ url: Api.postCollect, params });
}

export function postUnCollect(params) {
  return $Http.post({ url: Api.postUnCollect, params });
}
