<template>
  <div class="person-lr">
    <div class="left">{{ config.left }}</div>
    <div class="semi" v-if="config.isShowSemi || true">：</div>
    <div class="right">{{ config.right }}</div>
  </div>
</template>

<script>
  export default {
    props: {
      config: {
        type: Object,
        default: () => {}
      }
    }
  };
</script>

<style lang="less" scoped>
  .person-lr {
    .flex;
    align-items: center;
    margin-right: 20px;

    .left,
    .semi,
    .right {
      font-size: 12px;
      color: #575757;
    }

    .right {
      // margin-left: 5px;
      color: #000000;
    }
  }
</style>
