<!--
 * @Author: Kiana
 * @Description: icon button
-->
<template>
  <div class="icon-button">
    <!--    <i class="icon-button-icon" :class="`icon-${icon}`"></i>-->
    <Isvg :icon-class="icon" class-name="icon-button-icon" />
    <span class="icon-button-text"><slot></slot></span>
  </div>
</template>

<script>
  export default {
    name: '',
    data() {
      return {};
    },
    props: {
      icon: {
        type: String
      }
    },
    components: {},
    watch: {},
    computed: {},
    filters: {},
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {}
  };
</script>

<style lang="less" scoped>
  //   @bgcolor-gr-1:linear-gradient (270deg, #01C8D4 0%, #01C8D4 0%, #03D978 100%, #03D978 100%);
  //   @bgcolor-gr-2:linear-gradient (270deg, #01C8D4 0%, #01C8D4 0%, #03D978 100%, #03D978 100%);
  //   @bgcolor-gr-3:linear-gradient (0deg, #B5B5B5 0%, #B5B5B5 0%, #DCDDDD 100%, #DCDDDD 100%);
  //   @bgcolor-gr-4:linear-gradient (270deg, #00B4FF 0%, #00B4FF 0%, #1DD0D5 100%, #1DD0D5 100%);
  //   @color:red;
  .icon-button {
    width: 80px;
    height: 18px;
    .pointer;
    .font-12;
    border-radius: 8px;
    .flex-center-center;
    background-color: @color-blue;

    &-icon {
      width: 16px;
      height: 16px;
    }

    &-text {
      margin-left: 4px;
    }
  }
</style>
